/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
body {
  --graded: #ff7c00;
  --gradec: #ffc000;
  --gradeb: #c5e0b3;
  --gradea: #92d050;
  --grade: #82f802;
  --critical: #B33D36;
  --high: #EB973E;
  --medium: #FCE94E;
  --low: #6fb8f9;
  --info: #97d7ff;
  --not-applicable: #6d6d6d;
  --compliant: #61AD52;
  --tsuccess: #0dc97f;
  --terror: #f95668;
  --tinfo: #4285f4;
  --twarning: #f8b15e;
  font-family: "Roboto", sans-serif;
  background-color: #f7f8fc;
  width: 100%;
  scrollbar-width: 12px;
}

.toast-success {
  background-color: var(--tsuccess);
}

.toast-error {
  background-color: var(--terror);
}

.toast-info {
  background-color: var(--tinfo);
}

.toast-warning {
  background-color: var(--twarning);
}

.t-gray {
  color: var(--gray);
}

.t-c {
  color: var(--critical);
}

.t-h {
  color: var(--high);
}

.t-m {
  color: var(--medium);
}

.t-l {
  color: var(--low);
}

.t-i {
  color: var(--info);
}

.t-d {
  color: var(--not-applicable);
}

.t-g {
  color: var(--compliant);
}

.gradef,
.gradef:hover {
  color: #fff !important;
  background-color: var(--gradef) !important;
}

.graded,
.graded:hover {
  color: #444444 !important;
  background-color: var(--graded) !important;
}

.gradec,
.gradec:hover {
  color: #444444 !important;
  background-color: var(--gradec) !important;
}

.gradeb,
.gradeb:hover {
  color: #444444 !important;
  background-color: var(--gradeb) !important;
}

.gradea,
.gradea:hover {
  color: #444444 !important;
  background-color: var(--gradea) !important;
}

.grade,
.grade:hover {
  color: #444444 !important;
  background-color: var(--gradea) !important;
}

.spacer {
  flex: 1 1 auto;
}

.mini-form-field {
  transform: translateY(0em) scale(0.8) !important;
}

.imageWidth {
  width: 17px !important;
  margin-left: 9px;
}

.imageWidthDate {
  width: 25px !important;
  margin-left: 5px;
}

.vendor-logo {
  width: 18px;
}

.whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

.qr-code canvas {
  max-width: 300px !important;
  width: 100%;
}

.ufill-bg {
  height: 65px !important;
  background-color: #0076c0 !important;
  color: white !important;
}

.app-title {
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  margin-left: 1rem;
  color: #ffffff;
}

.tStats {
  margin-top: -15px;
  padding-bottom: 5px;
}

.regBtn {
  width: 150px;
  height: 40px;
  border-radius: 4px;
  background-color: rgb(53, 68, 99) !important;
  color: rgb(255, 255, 255) !important;
}

.regBtn:hover {
  border-bottom: 2px #ffdc00 solid;
  font-weight: bold;
}

.listofdsm-header-style {
  background-color: #e2edf2;
  height: 58px;
  margin-bottom: 13px;
  border-radius: 3px;
}

html::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

html::-webkit-scrollbar {
  width: 12px;
  background-color: rgba(245, 245, 245, 0.5098039216);
}

html::-webkit-scrollbar-thumb {
  background-color: rgba(124, 124, 124, 0.231372549);
}

.mat-select-panel-wrap {
  position: relative;
  top: 42px;
}